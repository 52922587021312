import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  Icon,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Transition from "../../../../../../../lib/dialogTransition";
import { IPrintDialogProps } from "../interfaces";
import PackingListToPrint from "./packingListToPrint";

function DettaglioPrintDialog({
  lotto,
  selectedItems,
  isOpen,
  onClose,
}: IPrintDialogProps) {
  const close = () => onClose();
  const componentRef = useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current ?? null,
    documentTitle: `packing_list_${lotto.codice}`,
  });
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xl"
    >
      <AppBar sx={{ position: "relative", displayPrint: "none" }}>
        <Toolbar variant="dense">
          <Typography variant="h5" sx={{ flex: 1 }} display="block">
            Packing List
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          p: 2,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PackingListToPrint
          ref={componentRef}
          lotto={lotto}
          selectedItems={selectedItems}
        />
      </Box>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handlePrint}>
          Stampa
        </Button>
        <Button onClick={close}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DettaglioPrintDialog;
