import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../../../../customize/settings";
import {
  getDettaglioByImage,
  getImageName,
} from "../../../../../lib/functions";
import { getJWT } from "../../../../../lib/jwt";
import { IThumbnailImageProps } from "./interfaces";

const IMAGE_URL = `${settings.apiUrl}image/`;
const i18_PATH = "prodotto.view.lottoImages.thumbnail.";

function Thumbnail({
  image,
  lotto,
  isEditMode,
  onOpen,
  onDelete,
  onUpdate,
  onCoverChange,
}: IThumbnailImageProps) {
  const { t } = useTranslation();
  const imageContainer = useRef<HTMLElement>();
  const [thumb, setThumb] = useState<string>();
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageName, setImageName] = useState<string>("");

  const getSize = () => {
    const width = imageContainer?.current?.clientWidth;
    setImageWidth(width || 0);
  };
  useEffect(() => {
    const jwt = getJWT();
    const _thumb = `${IMAGE_URL}${image.mdPath}?token=${jwt}`;
    setThumb(_thumb);
  }, [image]);
  useEffect(() => {
    const formulaLabel = settings.item?.images?.slabs?.label;
    if (!formulaLabel) return setImageName(image.name);
    const dettaglio = getDettaglioByImage(image, lotto.dettagli ?? []);
    if (!dettaglio) return setImageName(image.name);
    const name = getImageName(image, lotto, dettaglio, formulaLabel);
    if (!name) return setImageName(image.name);
    setImageName(name);
  }, [image, lotto]);
  useEffect(() => {
    window.addEventListener("resize", getSize);
    getSize();
    return () => window.removeEventListener("resize", getSize);
  }, []);
  return (
    <Box ref={imageContainer}>
      <Card>
        <CardActionArea onClick={() => onOpen(image)}>
          <CardMedia
            component="img"
            height={imageWidth * 0.5625}
            image={thumb}
            sx={{ objectFit: "contain" }}
            alt="cover"
          />
          <CardContent>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Typography variant="caption">{imageName}</Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        {isEditMode && (
          <CardActions>
            {!image.isCover && (
              <Tooltip
                title={t(`${i18_PATH}Imposta come immagine di copertina`)}
              >
                <IconButton onClick={() => onCoverChange(image)}>
                  <Icon
                    className="fa-light fa-circle-bookmark"
                    sx={{ overflow: "visible" }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {!!image.isCover && (
              <Tooltip title={t(`${i18_PATH}Immagine di copertina`)}>
                <IconButton>
                  <Icon
                    className="fa-solid fa-circle-bookmark"
                    sx={{ overflow: "visible" }}
                  />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t(`${i18_PATH}Modifica`)}>
              <IconButton onClick={() => onUpdate(image)}>
                <Icon
                  className="fa-light fa-pen-circle"
                  sx={{ overflow: "visible" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={t(`${i18_PATH}Elimina`)}>
              <IconButton onClick={() => onDelete(image)}>
                <Icon
                  className="fa-light fa-circle-trash"
                  sx={{ overflow: "visible" }}
                />
              </IconButton>
            </Tooltip>
          </CardActions>
        )}
      </Card>
    </Box>
  );
}

export default Thumbnail;
