import { Box } from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../../../../../customize/settings";
import { IDettaglio } from "../../../../../../interfaces/dettaglio/models";
import { dettagliFilterNotSold } from "../../../../../../lib/functions";
import { EShowColumnState } from "../interfaces";
import { createDefaultGridColumns } from "./defaultGridColumns";
import { getRowBundleClassName, hideColumns } from "./functions";
import GridFooter from "./gridFooter";
import { IDefaultGridProps, IGridFooterProps } from "./interfaces";

function DefaultGrid({
  dettagli,
  selectedItems,
  canShare,
  canViewCosts,
  canViewCommercials,
  canViewStoragePlace,
  canViewCommercialOptions,
  canSetCommercials,
  canSetStoragePlace,
  canSetFeatures,
  canSetCommercialOptions,
  showAdminColumns,
  onSelectedItemsChange,
  onUpdate,
  onShare,
  onSplit,
  onCommercialOptionsUpdate,
  onPackingList,
  onPackingListPrint,
  onViewImage,
}: IDefaultGridProps) {
  const { t } = useTranslation();
  const [rows, setRows] = useState<IDettaglio[]>([]);
  const [displayColumns, setDisplayColumns] = useState<GridColumns<IDettaglio>>(
    []
  );
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(
      settings.item?.grid?.initDefaultColumnVisibiliity ?? {}
    );
  const columns: GridColumns<IDettaglio> = useMemo(
    () =>
      createDefaultGridColumns({
        t,
        canViewCosts,
        canViewCommercials,
        canViewStoragePlace,
        canViewCommercialOptions,
        onViewImage,
      }),
    [
      t,
      canViewCosts,
      canViewCommercials,
      canViewStoragePlace,
      canViewCommercialOptions,
      onViewImage,
    ]
  );
  useEffect(() => {
    let columnsToHide: string[] = [];
    if (showAdminColumns === EShowColumnState.HIDE_ALL)
      columnsToHide = [
        "costo",
        "um_costo",
        "magazzino",
        "zona",
        "fila",
        "opzStato",
        "opzCliente",
        "agente",
        "opzNote",
        "prezzo",
        "um_prezzo",
      ];
    if (showAdminColumns === EShowColumnState.HIDE_PARTIAL)
      columnsToHide = [
        "costo",
        "um_costo",
        "magazzino",
        "zona",
        "fila",
        "opzStato",
        "opzCliente",
        "agente",
        "opzNote",
      ];
    const _displayColumns = !columnsToHide.length
      ? columns
      : hideColumns(columns, columnsToHide);
    setDisplayColumns(_displayColumns);
  }, [columns, showAdminColumns]);
  useEffect(() => {
    const _rows = dettagliFilterNotSold(dettagli);
    _rows.sort(
      (rowA, rowB) =>
        rowA.bundle - rowB.bundle ||
        rowA.lastreDa - rowB.lastreDa ||
        rowA.lastreA - rowB.lastreA
    );
    setRows(_rows);
  }, [dettagli]);
  const footerProps: IGridFooterProps = {
    selectedItems,
    canShare,
    canSetCommercials,
    canSetStoragePlace,
    canSetFeatures,
    canSetCommercialOptions,
    canViewCommercials,
    canViewCosts,
    showAdminColumns,
    onUpdate,
    onSplit,
    onShare,
    onCommercialOptionsUpdate,
    onPackingList,
    onPackingListPrint,
  };
  return (
    <Box
      sx={{
        "& .bundle-row--even": {
          bgcolor: "#f5f9ff",
        },
        "& .bundle-row--odd": {
          bgcolor: "#fff5f5",
        },
      }}
    >
      <DataGrid
        columns={displayColumns}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        rows={rows}
        disableSelectionOnClick={true}
        checkboxSelection={true}
        autoHeight={true}
        components={{
          Footer: GridFooter,
        }}
        componentsProps={{
          footer: footerProps,
        }}
        selectionModel={selectedItems.map((item) => item.id)}
        onSelectionModelChange={(selectedRowsIds) => {
          const _selectedRows = rows.filter((row) =>
            selectedRowsIds.includes(row.id)
          );
          onSelectedItemsChange(_selectedRows);
        }}
        getRowClassName={getRowBundleClassName}
        getRowHeight={() => settings.item?.grid?.defaulColumnsHeight ?? null}
      />
    </Box>
  );
}

export default DefaultGrid;
