import { ISettings } from "../../interfaces/settings/models";

export const settings: ISettings = {
  apiUrl: "https://mag.petris.it/api/public/",
  main: {
    soldList: false,
    materialList: false,
    qualitaList: true,
    prospects: false,
    clients: false,
  },
  list: {
    fullTextPaths: [
      "lotto.codice",
      "lotto.qualita.nome",
      "lotto.materiale.nome",
      "magazzino.nome",
      "stato_lavorazione.nome",
    ],
    grid: {
      groupingKeys: [
        "idLotto",
        "suffissoCodice",
        "idTipoLotto",
        "idMagazzino",
        "zona",
        "idDisponibilita",
        "idStatoLavorazione",
        "opzCliente",
      ],
      canUngroup: true,
      columnsToHide: ["zona", "fila"],
      initColumnVisibiliity: {
        cava: false,
        codiceCava: false,
        dataCarico: false,
        materiale: false,
        uso: false,
        magazzino: false,
      },
    },
    sideNav: {
      hiddenFilters: {
        qualitaCategorie: true,
        qualitaVenature: true,
        qualitaUsi: true,
        misX: true,
        misY: true,
        altreInformazioni: true,
      },
    },
  },
  item: {
    grid: {
      defaultColumnsToHide: ["zona", "fila", "bundle"],
      initDefaultColumnVisibiliity: {
        costo: false,
        um_costo: false,
      },
      defaultColumnsThumbnailWidth: 250,
      defaulColumnsHeight: "auto",
      historyColumnsToHide: ["zona", "fila", "bundle"],
      initHistoryColumnVisibiliity: {
        costo: false,
        um_costo: false,
      },
      packingListColumnsToHide: ["bundle"],
      initPackingListColumnsVisibility: {
        costo: false,
        um_costo: false,
      },
      actions: {
        commercial: {
          sections: {
            storage: {
              hidden: true,
            },
          },
        },
        packingList: { hidden: true },
      },
    },
    images: {
      slabs: {
        hiddenUpdateFields: {
          bundle: true,
          order: true,
        },
        hideIfNotExistRef: true,
        label:
          "[lotto.codice] - lastra: [image.slab] - [dettaglio.misX]x[dettaglio.misY]x[dettaglio.misZ] - [dettaglio.stato_lavorazione.nome]",
      },
    },
  },
  company: {
    name: "PETRIS S.R.L. (Soc. Uninominale)",
    address1: "Via Massa - Avenza, snc",
    address2: "54100 MASSA (ITALY)",
    vat: "01234010450",
    tel: "+390585093900",
    mail: "info@petris.it",
    web: "www.petris.it",
  },
};
