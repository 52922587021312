import { useEffect } from "react";
import { errorHandler } from "../../../error/handler";
import {
  getQualita,
  getQualitaExtList,
  getQualitaList,
  updateQualita,
} from "../../../fetch/lotto/attributes/qualita";
import { QualitaUpdateFormData } from "../../../interfaces/lotto/attributes/forms";
import {
  IQualita,
  IQualitaExt,
} from "../../../interfaces/lotto/attributes/models";
import { normalizer } from "../../../lib/normalizer";
import { useAppDispatch } from "../../../store/hooks";
import {
  emptyAction,
  getAction,
  listAction,
  listExtAction,
} from "../../../store/lotto/attributes/qualita/actions";
import {
  IQualitaExtListPayload,
  IQualitaListPayload,
} from "../../../store/lotto/attributes/qualita/types";
import {
  endActionToDoAction,
  messageAction,
  startActionToDoAction,
} from "../../../store/ui/actions";

export const useGetQualita = (id: number | null, query: number) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let ignore = false;
    const effect = async () => {
      if (!id || !query) return;
      const actionToDo = "get-qualita";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        const qualita = await getQualita(id);
        if (ignore)
          return dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(getAction(qualita));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
    return () => {
      ignore = true;
    };
  }, [dispatch, id, query]);
};

export const useQualitaList = (query: number) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let ignore = false;
    const effect = async () => {
      if (!query) return;
      const actionToDo = "list-qualita";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        const { results, items: _items } = await getQualitaList();
        if (ignore)
          return dispatch(endActionToDoAction({ actionToDo, success: true }));
        _items.sort((itemA, itemB) =>
          !!itemA?.ordinamento && !!itemB.ordinamento
            ? itemA.ordinamento - itemB.ordinamento
            : 0
        );
        const { list, items } = normalizer<number, IQualita>(_items, "id");
        const actionPayload: IQualitaListPayload = {
          results,
          list,
          items,
        };
        dispatch(listAction(actionPayload));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
      return () => {
        dispatch(emptyAction());
      };
    };
    effect();
    return () => {
      ignore = true;
    };
  }, [dispatch, query]);
};

export const useQualitaExtList = (query: number) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let ignore = false;
    const effect = async () => {
      if (!query) return;
      const actionToDo = "list-qualita-ext";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        const { results, items: _items } = await getQualitaExtList();
        if (ignore)
          return dispatch(endActionToDoAction({ actionToDo, success: true }));
        _items.sort((itemA, itemB) =>
          !!itemA?.ordinamento && !!itemB.ordinamento
            ? itemA.ordinamento - itemB.ordinamento
            : 0
        );
        const { list, items } = normalizer<number, IQualitaExt>(_items, "id");
        const actionPayload: IQualitaExtListPayload = {
          resultsExt: results,
          listExt: list,
          itemsExt: items,
        };
        dispatch(listExtAction(actionPayload));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
      return () => {
        dispatch(emptyAction());
      };
    };
    effect();
    return () => {
      ignore = true;
    };
  }, [dispatch, query]);
};

export const useQualitaUpdate = async (
  id: number | null,
  data: QualitaUpdateFormData
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let ignore = false;
    const effect = async () => {
      if (!id || !data) return;
      const actionToDo = "update-qualita";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        await updateQualita(id, data);
        if (ignore)
          return dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({ message: "Qualita aggiornata", kind: "success" })
        );
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
    return () => {
      ignore = true;
    };
  }, [dispatch, id, data]);
};
