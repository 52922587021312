import { Box, Button, ButtonGroup, Icon, Tooltip } from "@mui/material";
import { settings } from "../../../../../customize/settings";

interface ISelectViewToolbarProps {
  display: "grid" | "table";
  isGrouped: boolean;
  onSetDisplay: (display: "grid" | "table") => void;
  onSetIsGrouped: (isGrouped: boolean) => void;
}
function SelectViewToolbar({
  display,
  isGrouped,
  onSetDisplay,
  onSetIsGrouped,
}: ISelectViewToolbarProps) {
  const canUngroup = !!settings.list.grid?.canUngroup;
  return (
    <Box
      sx={{
        position: "absolute",
        right: 8,
        top: -32,
        backgroundColor: "#ffffff",
      }}
    >
      {display === "table" && canUngroup && (
        <ButtonGroup disableElevation>
          <Tooltip title="Raggruppa">
            <Button
              variant={isGrouped ? "contained" : "outlined"}
              endIcon={
                <Icon
                  className="fa-light fa-object-group"
                  sx={{
                    overflow: "visible",
                    marginRight: "4px",
                    marginLeft: "-8px",
                  }}
                />
              }
              sx={{ endIcon: { margin: 0 } }}
              onClick={() => onSetIsGrouped(true)}
            ></Button>
          </Tooltip>
          <Tooltip title="Separa">
            <Button
              variant={!isGrouped ? "contained" : "outlined"}
              endIcon={
                <Icon
                  className="fa-light fa-object-ungroup"
                  sx={{
                    overflow: "visible",
                    marginRight: "4px",
                    marginLeft: "-8px",
                  }}
                />
              }
              onClick={() => onSetIsGrouped(false)}
            ></Button>
          </Tooltip>
        </ButtonGroup>
      )}
      <ButtonGroup disableElevation>
        <Tooltip title="Griglia">
          <Button
            variant={display === "grid" ? "contained" : "outlined"}
            endIcon={
              <Icon
                className="fa-light fa-grid"
                sx={{
                  overflow: "visible",
                  marginRight: "4px",
                  marginLeft: "-8px",
                }}
              />
            }
            sx={{ endIcon: { margin: 0 } }}
            onClick={() => onSetDisplay("grid")}
          ></Button>
        </Tooltip>
        <Tooltip title="Tabella">
          <Button
            variant={display === "table" ? "contained" : "outlined"}
            endIcon={
              <Icon
                className="fa-light fa-table"
                sx={{
                  overflow: "visible",
                  marginRight: "4px",
                  marginLeft: "-8px",
                }}
              />
            }
            onClick={() => onSetDisplay("table")}
          ></Button>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );
}

export default SelectViewToolbar;
