import {
  Collapse,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDisponibilita } from "../../../../../../../interfaces/dettaglio/attributes/models";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IFilterProps } from "../../interfaces";

const i18_PATH = "prodotto.list.filters.";

function DisponibilitaFilter({ filters, setFilters }: IFilterProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const disponibilitaList = useAppSelector(
    (state) => state.dettaglio_disponibilita.list
  );
  const disponibilitaItems = useAppSelector(
    (state) => state.dettaglio_disponibilita.items
  );

  const list: IDisponibilita[] = useMemo(
    () =>
      !!disponibilitaList && !!disponibilitaItems
        ? disponibilitaList.map((id) => disponibilitaItems[id])
        : [],
    [disponibilitaList, disponibilitaItems]
  );

  const toggle = () => {
    setOpen(!open);
  };

  const toggleSelected = (item: IDisponibilita) => {
    if (isFilterAndValueSelected(filters, EOptionType.disponibilita, item.id)) {
      const found =
        filters.filter(
          (f) =>
            f.type === EOptionType.disponibilita &&
            [1, 2].includes(f.numericValue as number)
        ).length === 1;
      if (found)
        setFilters(
          filters.filter(
            (opt) =>
              !(
                opt.type === EOptionType.disponibilita &&
                opt.numericValue === item.id
              ) && opt.type !== EOptionType.cliente
          )
        );
      else
        setFilters(
          filters.filter(
            (opt) =>
              !(
                opt.type === EOptionType.disponibilita &&
                opt.numericValue === item.id
              )
          )
        );
    } else {
      setFilters([
        ...filters, //.filter(opt => (opt.type !== EOptionType.disponibilita && opt.type !== EOptionType.cliente)),
        {
          type: EOptionType.disponibilita,
          numericValue: item.id,
          stringValue: item.nome,
        },
      ]);
    }
  };
  return (
    <>
      <ListItemButton onClick={toggle}>
        <ListItemIcon>
          <Icon
            className="fa-light fa-briefcase"
            sx={{ overflow: "visible" }}
          />
        </ListItemIcon>
        <ListItemText primary={t(`${i18_PATH}Disponibilita`)} />
        {open ? (
          <Icon
            className="fa-light fa-angle-up"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        ) : (
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true}>
          {list.map((item) => (
            <ListItemButton
              key={item.id}
              sx={{ display: "flex", pl: 5, pr: 1 }}
              onClick={() => toggleSelected(item)}
              selected={isFilterAndValueSelected(
                filters,
                EOptionType.disponibilita,
                item.id
              )}
            >
              <ListItemIcon sx={{ minWidth: 20 }}>
                <Icon
                  className="fa-solid fa-circle"
                  sx={{
                    fontSize: 12,
                    overflow: "visible",
                    color: item.coloreWeb,
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ flex: 1 }} primary={item.nome} />
              <ListItemIcon sx={{ minWidth: 0 }}>
                {isFilterAndValueSelected(
                  filters,
                  EOptionType.disponibilita,
                  item.id
                ) && (
                  <Icon
                    className="fa-light fa-check"
                    sx={{ overflow: "visible" }}
                  />
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default DisponibilitaFilter;
