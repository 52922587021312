import { List, ListSubheader } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IFilterProps } from "../../interfaces";
import StatoLavorazioneFilter from "./lavorazioni";

const i18_PATH = "prodotto.list.filters.";

function LavorazioniFiltersContainer({ filters, setFilters }: IFilterProps) {
  const { t } = useTranslation();
  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={
        <ListSubheader component="div">
          {t(`${i18_PATH}LavorazioneHeader`)}
        </ListSubheader>
      }
    >
      <StatoLavorazioneFilter filters={filters} setFilters={setFilters} />
    </List>
  );
}

export default LavorazioniFiltersContainer;
