import { Box, Icon, Tooltip } from "@mui/material";
import {
  dettaglioIsBlock,
  dettaglioIsSlab,
  dettaglioIsTiles,
} from "../../../lib/functions";
import { ICellTipoLottoParams } from "./interfaces";

export function CellTipoLotto({ dettaglio }: ICellTipoLottoParams) {
  const color = dettaglio.disponibilita?.coloreWeb ?? "inherit";
  const tooltipSuffix = dettaglio.disponibilita?.nome
    ? ` - ${dettaglio.disponibilita.nome}`
    : "";
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {dettaglioIsBlock(dettaglio) && (
        <Tooltip title={`Blocco${tooltipSuffix}`}>
          <Icon
            className="fa-light fa-cube"
            sx={{ overflow: "visible", color }}
          />
        </Tooltip>
      )}
      {dettaglioIsSlab(dettaglio) && (
        <Tooltip title={`Lastre${tooltipSuffix}`}>
          <Icon
            className="fa-light fa-layer-group"
            sx={{ overflow: "visible", color }}
          />
        </Tooltip>
      )}
      {dettaglioIsTiles(dettaglio) && (
        <Tooltip title={`Marmette${tooltipSuffix}`}>
          <Icon
            className="fa-light fa-bring-forward"
            sx={{ overflow: "visible", color }}
          />
        </Tooltip>
      )}
    </Box>
  );
}
