export enum EOptionType {
  "statoLavorazione" = "statoLavorazione",
  "categoria" = "categoria",
  "cava" = "cava",
  "fondo" = "fondo",
  "magazzino" = "magazzino",
  "zona" = "zona",
  "fila" = "fila",
  "materiale" = "materiale",
  "qualita" = "qualita",
  "tipoLotto" = "tipoLotto",
  "uso" = "uso",
  "venatura" = "venatura",
  "prezzo" = "prezzo",
  "disponibilita" = "disponibilita",
  "cliente" = "cliente",
  "fullText" = "fullText",
  "daSegare" = "daSegare",
  "misX" = "misX",
  "misY" = "misY",
  "misZ" = "misZ",
}
