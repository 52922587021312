import { List, ListSubheader } from "@mui/material";
import { useTranslation } from "react-i18next";
import { settings } from "../../../../../../../customize/settings";
import { IFilterProps } from "../../interfaces";
import MisXFilter from "./misX";
import MisYFilter from "./misY";
import MisZFilter from "./misZ";

const i18_PATH = "prodotto.list.filters.";

function MisureFiltersContainer({ filters, setFilters }: IFilterProps) {
  const { t } = useTranslation();
  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={
        <ListSubheader component="div">
          {t(`${i18_PATH}MisureHeader`)}
        </ListSubheader>
      }
    >
      {!settings.list?.sideNav?.hiddenFilters?.misX && (
        <MisXFilter filters={filters} setFilters={setFilters} />
      )}
      {!settings.list?.sideNav?.hiddenFilters?.misY && (
        <MisYFilter filters={filters} setFilters={setFilters} />
      )}
      {!settings.list?.sideNav?.hiddenFilters?.misZ && (
        <MisZFilter filters={filters} setFilters={setFilters} />
      )}
    </List>
  );
}

export default MisureFiltersContainer;
