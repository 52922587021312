import { ILotto } from "../../../interfaces/lotto/models";

export const GET = "lotto/get";
export const LIST = "lotto/list";
export const EMPTY = "lotto/empty";
export const GET_SHARE_TOKEN = "lotto/get_share_token";
export const CLEAR_SHARE_TOKEN = "lotto/clear_share_token";
export const UPDATE_COMMERCIALS = "lotto/update_commercials";
export const UPDATE_STORAGE = "lotto/update_storage";
export const UPDATE_STATO_LAVORAZIONE = "lotto/update_stato_lavorazione";
export const UPDATE_NOTES = "lotto/update_notes";
export const UPDATE_MEASURES = "lotto/update_measures";
export const UPDATE_PUBLISHED = "lotto/update_published";
export const UPDATE_PUBLISHED_DETAILS = "lotto/update_published_details";

export interface ILottoState {
  results: number;
  list: number[];
  items: Record<number, ILotto> | null;
  shareToken: string | null;
}

export const initialState: ILottoState = {
  results: 0,
  list: [],
  items: null,
  shareToken: null,
};
