import {
  Collapse,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { IFilterProps } from "../../interfaces";

const i18_PATH = "prodotto.list.filters.";

function AltreInformazioniBloccoFilter({ filters, setFilters }: IFilterProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => {
    setOpen(!open);
  };

  const toggleSelected = () => {
    if (isFilterAndValueSelected(filters, EOptionType.daSegare, true)) {
      setFilters(filters.filter((opt) => !(opt.type === EOptionType.daSegare)));
    } else {
      setFilters([
        ...filters,
        {
          type: EOptionType.daSegare,
          booleanValue: true,
        },
      ]);
    }
  };

  return (
    <>
      <ListItemButton onClick={toggle}>
        <ListItemIcon>
          <Icon className="fa-light fa-info" sx={{ overflow: "visible" }} />
        </ListItemIcon>
        <ListItemText primary={t(`${i18_PATH}AltreInformazioni`)} />
        {open ? (
          <Icon
            className="fa-light fa-angle-up"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        ) : (
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true}>
          <ListItemButton
            sx={{ display: "flex", pl: 5, pr: 1 }}
            onClick={() => toggleSelected()}
            selected={isFilterAndValueSelected(
              filters,
              EOptionType.daSegare,
              true
            )}
          >
            <ListItemText sx={{ flex: 1 }} primary="Blocchi da segare" />
            <ListItemIcon sx={{ minWidth: 0 }}>
              {isFilterAndValueSelected(
                filters,
                EOptionType.daSegare,
                true
              ) && (
                <Icon
                  className="fa-light fa-check"
                  sx={{ overflow: "visible" }}
                />
              )}
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
}

export default AltreInformazioniBloccoFilter;
