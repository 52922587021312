import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Icon,
  Tooltip,
} from "@mui/material";
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../../customize/settings";
import { useAppSelector } from "../../../store/hooks";
import { IImageUploadProps } from "./dialogs/upload/interfaces";
import UploadDialog from "./dialogs/upload/uploadDialog";

const IMAGE_URL = `${settings.apiUrl}qualitaImage/`;

const i18_PATH = "qualita.update.";

function ImageUpload({ qualita }: IImageUploadProps) {
  const { t } = useTranslation();
  const imageContainer = useRef<HTMLElement>();
  const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);
  const image = useMemo(
    () =>
      !!qualita?.image?.mdPath ? `${IMAGE_URL}${qualita.image.mdPath}` : null,
    [qualita]
  );
  const getSize = () => {
    const width = imageContainer?.current?.clientWidth;
    setImageWidth(width || 0);
  };
  const openUploadDialog = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsUploadOpen(true);
  };
  const closeUpload = () => {
    setIsUploadOpen(false);
  };
  useEffect(() => {
    window.addEventListener("resize", getSize);
    getSize();
    return () => window.removeEventListener("resize", getSize);
  }, []);
  useEffect(() => {
    if (!lastActionDone) return;
    if (lastActionDone === "upload-immagine-qualita") setIsUploadOpen(false);
  }, [lastActionDone]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          border: "1px solid #e3e3e3",
          borderRadius: 4,
        }}
        ref={imageContainer}
      >
        <Card sx={{ flex: 1 }}>
          <CardActionArea onClick={openUploadDialog}>
            {!!image && (
              <CardMedia
                component="img"
                height={imageWidth}
                image={image}
                sx={{ objectFit: "contain" }}
                alt="cover"
              />
            )}
            {!image && (
              <Tooltip title={t(`${i18_PATH}NoImmagine`)}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: imageWidth,
                  }}
                >
                  <Icon
                    className="fa-light fa-image-slash"
                    fontSize="large"
                    color="action"
                    sx={{ overflow: "visible" }}
                  />
                </Box>
              </Tooltip>
            )}
          </CardActionArea>
        </Card>
      </Box>
      {!!qualita && (
        <UploadDialog
          idQualita={qualita.id}
          isOpen={isUploadOpen}
          close={closeUpload}
        />
      )}
    </>
  );
}

export default ImageUpload;
