import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import { emptyAction, getAction, listAction, listSoldAction } from "./actions";
import { initialState } from "./types";

export default createReducer(initialState, (builder) => {
  builder.addCase(listAction, (state, action) =>
    produce(state, (draft) => {
      const {
        results,
        list,
        items,
        groupedResults,
        groupedList,
        groupedItems,
      } = action.payload;
      draft.results = results;
      draft.list = list;
      draft.items = items;
      draft.groupedResults = groupedResults;
      draft.groupedList = groupedList;
      draft.groupedItems = groupedItems;
    })
  );
  builder.addCase(listSoldAction, (state, action) =>
    produce(state, (draft) => {
      const { soldResults, soldList, soldItems } = action.payload;
      draft.soldResults = soldResults;
      draft.soldList = soldList;
      draft.soldItems = soldItems;
    })
  );
  builder.addCase(getAction, (state, action) =>
    produce(state, (draft) => {
      const dettaglio = action.payload;
      if (!draft.items) draft.items = {};
      draft.items[dettaglio.id] = dettaglio;
    })
  );
  builder.addCase(emptyAction, () => initialState);
});
