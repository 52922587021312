import { List, ListSubheader } from "@mui/material";
import { useTranslation } from "react-i18next";
import { settings } from "../../../../../../../customize/settings";
import CaveFilter from "./cave";
import { IMaterialeFiltersContainerProps } from "./interfaces";
import MaterialiFilter from "./materiali";
import TipiLottoFilter from "./tipiLotto";

const i18_PATH = "prodotto.list.filters.";

function MaterialeFiltersContainer({
  filters,
  setFilters,
  canViewQuarry,
}: IMaterialeFiltersContainerProps) {
  const { t } = useTranslation();
  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={
        <ListSubheader component="div">
          {t(`${i18_PATH}MaterialeHeader`)}
        </ListSubheader>
      }
    >
      {!settings.list?.sideNav?.hiddenFilters?.materialeMateriali && (
        <MaterialiFilter filters={filters} setFilters={setFilters} />
      )}
      {!settings.list?.sideNav?.hiddenFilters?.materialeTipoLotto && (
        <TipiLottoFilter filters={filters} setFilters={setFilters} />
      )}
      {canViewQuarry &&
        !settings.list?.sideNav?.hiddenFilters?.materialeCave && (
          <CaveFilter filters={filters} setFilters={setFilters} />
        )}
    </List>
  );
}

export default MaterialeFiltersContainer;
