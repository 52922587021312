import { Box, Typography } from "@mui/material";
import { CSSProperties, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IPackingListToPrintProps } from "../interfaces";
import logo from "../../../../../../../customize/assets/logo.png";
import { settings } from "../../../../../../../customize/settings";
import {
  getTotalMQ,
  getTotalNumberOfSlabs,
} from "../../../../../../../lib/functions";

const i18_PATH = "prodotto.view.lottoDettagli.grids.packingListToPrintColumns.";
const tdStyle: CSSProperties = {
  border: "1px solid grey",
  padding: 10,
  textAlign: "center",
};
const thStyle: CSSProperties = {
  fontWeight: "bold",
  border: "1px solid grey",
  padding: 10,
  textAlign: "center",
  backgroundColor: "lightGrey",
};

const PackingListToPrint = forwardRef<
  HTMLDivElement | null,
  IPackingListToPrintProps
>(({ lotto, selectedItems }, ref) => {
  const { t } = useTranslation();
  const totalSlabs = useMemo(
    () => getTotalNumberOfSlabs(selectedItems),
    [selectedItems]
  );
  const totalDimensions = useMemo(
    () => getTotalMQ(selectedItems, 2, 2),
    [selectedItems]
  );

  return (
    <Box ref={ref} sx={{ width: "100%", "@media print": { margin: 4 } }}>
      <Box
        sx={{
          display: "none",
          "@media print": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <img src={logo} alt="logo" height={150} />
      </Box>
      <Box
        sx={{ py: 2, display: "none", "@media print": { display: "block" } }}
      >
        {!!settings.company?.name && (
          <Typography variant="body1">{settings.company.name}</Typography>
        )}
        {!!settings.company?.address1 && (
          <Typography variant="body2">{settings.company?.address1}</Typography>
        )}
        {!!settings.company?.address2 && (
          <Typography variant="body2">{settings.company?.address2}</Typography>
        )}
        {!!settings.company?.vat && (
          <Typography variant="body2">P.I. {settings.company?.vat}</Typography>
        )}
        {!!settings.company?.tel && (
          <Typography variant="body2">Tel. {settings.company?.tel}</Typography>
        )}
        {!!settings.company?.mail && (
          <Typography variant="body2">
            Mail. {settings.company?.mail}
          </Typography>
        )}
        {!!settings.company?.web && (
          <Typography variant="body2">Web. {settings.company?.web}</Typography>
        )}
      </Box>
      <Typography sx={{ display: "none", "@media print": { display: "block" } }} align="right" color="primary" variant="h3">
        Packing List
      </Typography>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={thStyle}>{t(`${i18_PATH}Qualita`)}</th>
            <th style={thStyle}>{t(`${i18_PATH}Finitura`)}</th>
            <th style={thStyle}>{t(`${i18_PATH}Lotto`)}</th>
            <th style={thStyle}>{t(`${i18_PATH}Lastre`)}</th>
            <th style={thStyle}>{t(`${i18_PATH}Pezzi`)}</th>
            <th style={thStyle} colSpan={3}>
              {t(`${i18_PATH}Misure`)}
            </th>
            <th style={thStyle}>{t(`${i18_PATH}Quantita`)}</th>
          </tr>
        </thead>
        <tbody>
          {selectedItems.map((item) => (
            <tr key={item.id}>
              <td style={tdStyle}>{lotto?.qualita?.nome}</td>
              <td style={tdStyle}>{item?.stato_lavorazione?.nome}</td>
              <td style={tdStyle}>{lotto.codice}</td>
              <td style={tdStyle}>
                {item.lastreDa} / {item.lastreA}
              </td>
              <td style={tdStyle}>{item.pezzi}</td>
              <td style={tdStyle}>{item.misX}</td>
              <td style={tdStyle}>{item.misY}</td>
              <td style={tdStyle}>{item.misZ}</td>
              <td style={tdStyle}>{item.quantitaUM}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <th style={thStyle}>{totalSlabs}</th>
            <td></td>
            <td></td>
            <td></td>
            <th style={thStyle}>{totalDimensions}</th>
          </tr>
        </tfoot>
      </table>
    </Box>
  );
});

export default PackingListToPrint;
