import { List, ListSubheader } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IFilterProps } from "../../interfaces";
import AltreInformazioniBloccoFilter from "./blocco";

const i18_PATH = "prodotto.list.filters.";


function AltreInformazioniFiltersContainer({
  filters,
  setFilters,
}: IFilterProps) {
  const { t } = useTranslation();
  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={
        <ListSubheader component="div">{t(`${i18_PATH}AltreInformazioniHeader`)} </ListSubheader>
      }
    >
      <AltreInformazioniBloccoFilter
        filters={filters}
        setFilters={setFilters}
      />
    </List>
  );
}

export default AltreInformazioniFiltersContainer;
