import {
  Collapse,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { isNotEmpty } from "../../../../../../../lib/utils";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IFilterProps } from "../../interfaces";

const i18_PATH = "prodotto.list.filters.";

function ClientiFilter({ filters, setFilters }: IFilterProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const dettaglioItems = useAppSelector((state) => state.dettaglio.items || {});

  const clienti: string[] = useMemo(() => {
    const idDisponiblilita = filters
      .filter((f) => f.type === EOptionType.disponibilita && !!f.numericValue)
      .map((filter) => filter.numericValue)
      .filter(isNotEmpty);

    const clientiArray = Object.values(dettaglioItems)
      .filter((dettaglio) =>
        idDisponiblilita.includes(dettaglio.idDisponibilita as number)
      )
      .map((dettaglio) => dettaglio.opzCliente as string)
      .filter(Boolean);
    const clientiSet = new Set(clientiArray);
    const clienti = Array.from(clientiSet);
    clienti.sort((a, b) => (a > b ? 1 : -1));
    return clienti;
  }, [filters, dettaglioItems]);

  const toggle = () => {
    setOpen(!open);
  };

  const toggleSelected = (item: string) => {
    if (isFilterAndValueSelected(filters, EOptionType.cliente, item)) {
      setFilters(
        filters.filter(
          (opt) =>
            !(opt.type === EOptionType.cliente && opt.stringValue === item)
        )
      );
    } else {
      setFilters([
        ...filters,
        {
          type: EOptionType.cliente,
          stringValue: item,
        },
      ]);
    }
  };

  return (
    <>
      <ListItemButton onClick={toggle}>
        <ListItemIcon>
          <Icon className="fa-light fa-users" sx={{ overflow: "visible" }} />
        </ListItemIcon>
        <ListItemText primary={t(`${i18_PATH}Clienti`)} />
        {open ? (
          <Icon
            className="fa-light fa-angle-up"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        ) : (
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true}>
          {clienti.map((item) => (
            <ListItemButton
              key={item}
              sx={{ display: "flex", pl: 5, pr: 1 }}
              onClick={() => toggleSelected(item)}
              selected={isFilterAndValueSelected(
                filters,
                EOptionType.cliente,
                item
              )}
            >
              <ListItemText sx={{ flex: 1 }} primary={item} />
              <ListItemIcon sx={{ minWidth: 0 }}>
                {isFilterAndValueSelected(
                  filters,
                  EOptionType.cliente,
                  item
                ) && (
                  <Icon
                    className="fa-light fa-check"
                    sx={{ overflow: "visible" }}
                  />
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default ClientiFilter;
