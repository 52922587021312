import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  Icon,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLottoSetPublish } from "../../../../../../effects/lotto/lotto";
import { LottoPublishFormData } from "../../../../../../interfaces/lotto/forms";
import Transition from "../../../../../../lib/dialogTransition";
import { IPublishDialogProps } from "./interfaces";

const i18_PATH = "prodotto.view.lottoMain.dialog.publishOnTheWeb.";

function PublishDialog({ lotto, isOpen, close }: IPublishDialogProps) {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<LottoPublishFormData>(null);

  const onClose = () => {
    setFormData(null);
    close();
  };

  const onSubmit = () => {
    setFormData({ isWebPublished: !lotto.pubblicatoSuWeb });
  };
  useEffect(() => {
    setFormData(null);
  }, [isOpen, lotto]);

  useLottoSetPublish(lotto.id, formData);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar variant="dense">
          <Typography variant="h5" sx={{ flex: 1 }} display="block">
            {t(`${i18_PATH}Title`)}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2, overflowY: "auto" }}>
        {!lotto.pubblicatoSuWeb && (
          <Typography variant="h5" sx={{ flex: 1 }} display="block">
            {t(`${i18_PATH}PublishInfo`)}
          </Typography>
        )}
        {lotto.pubblicatoSuWeb && (
          <Typography variant="h5" sx={{ flex: 1 }} display="block">
            {t(`${i18_PATH}RemovePublicationInfo`)}
          </Typography>
        )}
      </Box>
      <DialogActions>
        <Button onClick={onSubmit} variant="contained" color="primary">
          {t(`${i18_PATH}Si`)}
        </Button>
        <Button onClick={onClose}> {t(`${i18_PATH}No`)}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PublishDialog;
