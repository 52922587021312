import {
  Box,
  Button,
  Collapse,
  Icon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IFilterProps } from "../../interfaces";

const i18_PATH = "prodotto.list.filters.";

function MisYFilter({ filters, setFilters }: IFilterProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [min, setMin] = useState<string>("");
  const [max, setMax] = useState<string>("");

  const toggle = () => {
    setOpen(!open);
  };

  const onMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMin(event.target.value);
  };

  const onMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMax(event.target.value);
  };

  const submitFilter = () => {
    if (!min && !max) return;
    let stringValue = t(`${i18_PATH}MisY`) + " - ";
    const _min = !isNaN(parseFloat(min)) ? parseFloat(min) : null;
    const _max = !isNaN(parseFloat(max)) ? parseFloat(max) : null;
    if (_min !== null) stringValue += `da ${_min} `;
    if (_max !== null) stringValue += `a ${_max} `;
    setFilters([
      ...filters.filter((opt) => !(opt.type === EOptionType.misY)),
      {
        type: EOptionType.misY,
        numericValues: [_min as number, _max as number],
        stringValue,
      },
    ]);
  };
  return (
    <>
      <ListItemButton onClick={toggle}>
        <ListItemIcon>
          <Icon className="fa-light fa-ruler" sx={{ overflow: "visible" }} />
        </ListItemIcon>
        <ListItemText primary={t(`${i18_PATH}MisY`)} />
        {open ? (
          <Icon
            className="fa-light fa-angle-up"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        ) : (
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ pl: 4, pr: 2, pv: 2 }}>
          <TextField
            size="small"
            margin="dense"
            label={t(`${i18_PATH}MisYMin`)}
            name="min"
            type="number"
            value={min}
            onChange={onMinChange}
            sx={{ flex: 1, width: "100%" }}
          />
          <TextField
            size="small"
            margin="dense"
            label={t(`${i18_PATH}MisYMax`)}
            name="max"
            type="number"
            value={max}
            onChange={onMaxChange}
            sx={{ flex: 1, width: "100%" }}
          />
        </Box>
        <Box sx={{ display: "flex", pl: 4, pr: 2, pb: 2 }}>
          <Button
            onClick={submitFilter}
            size="small"
            variant="outlined"
            sx={{ flex: 1 }}
          >
            Filtra
          </Button>
        </Box>
      </Collapse>
    </>
  );
}

export default MisYFilter;
