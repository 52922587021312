import * as React from "react";
import { errorHandler } from "../../error/handler";
import {
  getDettaglio,
  getDettaglioList,
  getDettaglioSoldList,
  setWebPublishedDettaglio,
  splitDettaglio,
  updateCommercialOptionsDettaglio,
  updateCommercialsDettaglio,
  updateMeasuresDettaglio,
  updateNotesDettaglio,
  updateStatoLavorazioneDettaglio,
  updateStorageDettaglio,
} from "../../fetch/dettaglio/dettaglio";
import {
  DettaglioPublishFormData,
  DettaglioSplitFormData,
  DettaglioUpdateCommercialOptionsFormData,
  DettaglioUpdateCommercialsFormData,
  DettaglioUpdateMeasuresFormData,
  DettaglioUpdateNotesFormData,
  DettaglioUpdateStatoLavorazioneFormData,
  DettaglioUpdateStorageFormData,
} from "../../interfaces/dettaglio/forms";
import { IDettaglio } from "../../interfaces/dettaglio/models";
import { calcQuantityUM } from "../../lib/functions";
import { dettaglioGrouping } from "../../lib/grouping";
import { normalizerTransform } from "../../lib/normalizer";
import { dettaglioTransform } from "../../lib/transform";
import {
  emptyAction,
  getAction,
  listAction,
  listSoldAction,
} from "../../store/dettaglio/dettaglio/actions";
import {
  IDettaglioListPayload,
  IDettaglioListSoldPayload,
} from "../../store/dettaglio/dettaglio/types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  updateCommercialsAction,
  updateMeasuresAction,
  updateNotesAction,
  updatePubishedDetailsAction,
  updateStatoLavorazioneAction,
  updateStorageAction,
} from "../../store/lotto/lotto/actions";
import {
  endActionToDoAction,
  messageAction,
  startActionToDoAction,
} from "../../store/ui/actions";

export const useDettaglioGet = (id: number | null, query: number) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const effect = async () => {
      if (!(!!id && query)) return;
      const actionToDo = "get-dettaglio";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        const dettaglioLotto = await getDettaglio(id);
        dispatch(getAction(dettaglioLotto));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
  }, [dispatch, id, query]);
};

export const useDettaglioList = (query: number) => {
  const dispatch = useAppDispatch();
  const lottoMap = useAppSelector((state) => state.lotto.items);
  const tipoLottoMap = useAppSelector(
    (state) => state.dettaglio_tipoLotto.items
  );
  const magazzinoMap = useAppSelector((state) => state.place_magazzino.items);
  const zonaMap = useAppSelector((state) => state.place_zona.items);
  const filaMap = useAppSelector((state) => state.place_fila.items);
  const disponibilitaMap = useAppSelector(
    (state) => state.dettaglio_disponibilita.items
  );
  const statoLavorazioneMap = useAppSelector(
    (state) => state.dettaglio_stato_lavorazione.items
  );
  React.useEffect(() => {
    const effect = async () => {
      if (
        !(
          !!query &&
          !!lottoMap &&
          !!tipoLottoMap &&
          !!magazzinoMap &&
          !!zonaMap &&
          !!filaMap &&
          !!disponibilitaMap &&
          !!statoLavorazioneMap
        )
      )
        return;
      const actionToDo = "list-dettaglio";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        const { results, items: _items } = await getDettaglioList();
        const ungroupedItemsArray = _items.map((item) => {
          const quantitaUM = calcQuantityUM(item);
          return {
            ...item,
            quantitaUM,
          };
        });
        const { list, items } = normalizerTransform<
          number,
          IDettaglio,
          IDettaglio
        >(ungroupedItemsArray, (item) =>
          dettaglioTransform(
            item,
            lottoMap,
            tipoLottoMap,
            magazzinoMap,
            zonaMap,
            filaMap,
            disponibilitaMap,
            statoLavorazioneMap
          )
        );
        const groupedItemsArray = dettaglioGrouping(ungroupedItemsArray);
        const { list: groupedList, items: groupedItems } = normalizerTransform<
          number,
          IDettaglio,
          IDettaglio
        >(groupedItemsArray, (item) =>
          dettaglioTransform(
            item,
            lottoMap,
            tipoLottoMap,
            magazzinoMap,
            zonaMap,
            filaMap,
            disponibilitaMap,
            statoLavorazioneMap
          )
        );

        const actionPayload: IDettaglioListPayload = {
          results,
          list,
          items,
          groupedResults: results,
          groupedList,
          groupedItems,
        };
        dispatch(listAction(actionPayload));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
      return () => {
        dispatch(emptyAction());
      };
    };
    effect();
  }, [
    dispatch,
    query,
    lottoMap,
    tipoLottoMap,
    magazzinoMap,
    zonaMap,
    filaMap,
    disponibilitaMap,
    statoLavorazioneMap,
  ]);
};

export const useDettaglioSoldList = (query: number) => {
  const dispatch = useAppDispatch();
  const lottoMap = useAppSelector((state) => state.lotto.items);
  const tipoLottoMap = useAppSelector(
    (state) => state.dettaglio_tipoLotto.items
  );
  const magazzinoMap = useAppSelector((state) => state.place_magazzino.items);
  const zonaMap = useAppSelector((state) => state.place_zona.items);
  const filaMap = useAppSelector((state) => state.place_fila.items);
  const disponibilitaMap = useAppSelector(
    (state) => state.dettaglio_disponibilita.items
  );
  const statoLavorazioneMap = useAppSelector(
    (state) => state.dettaglio_stato_lavorazione.items
  );
  React.useEffect(() => {
    const effect = async () => {
      if (
        !(
          !!query &&
          !!lottoMap &&
          !!tipoLottoMap &&
          !!magazzinoMap &&
          !!zonaMap &&
          !!filaMap &&
          !!disponibilitaMap &&
          !!statoLavorazioneMap
        )
      )
        return;
      const actionToDo = "list-dettaglio-sold";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        const { results, items: _items } = await getDettaglioSoldList();
        const groupedItems = dettaglioGrouping(
          _items.map((item) => {
            const quantitaUM = calcQuantityUM(item);
            return {
              ...item,
              quantitaUM,
            };
          })
        );
        const { list, items } = normalizerTransform<
          number,
          IDettaglio,
          IDettaglio
        >(groupedItems, (item) =>
          dettaglioTransform(
            item,
            lottoMap,
            tipoLottoMap,
            magazzinoMap,
            zonaMap,
            filaMap,
            disponibilitaMap,
            statoLavorazioneMap
          )
        );
        const actionPayload: IDettaglioListSoldPayload = {
          soldResults: results,
          soldList: list,
          soldItems: items,
        };
        dispatch(listSoldAction(actionPayload));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
      return () => {
        dispatch(emptyAction());
      };
    };
    effect();
  }, [
    dispatch,
    query,
    lottoMap,
    tipoLottoMap,
    magazzinoMap,
    zonaMap,
    filaMap,
    disponibilitaMap,
    statoLavorazioneMap,
  ]);
};

export const useDettaglioSplit = (
  id: number | null,
  data: DettaglioSplitFormData
) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const effect = async () => {
      if (!id || !data) return;
      const { rows, selectedRow } = data;
      const actionToDo = "split-dettaglio";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        for (const row of rows) {
          const { createdId } = await splitDettaglio(id, {
            quantity: row.quantity ?? 0,
          });
          await updateMeasuresDettaglio(createdId, {
            misX: row.misX ?? 0,
            misY: row.misY ?? 0,
            misZ: row.misZ ?? 0,
          });
          await updateCommercialsDettaglio(createdId, {
            lastreDa: row.lastreDa ?? 0,
            lastreA: row.lastreA ?? 0,
          });
        }
        await updateMeasuresDettaglio(id, {
          misX: selectedRow.misX ?? 0,
          misY: selectedRow.misY ?? 0,
          misZ: selectedRow.misZ ?? 0,
        });
        await updateCommercialsDettaglio(id, {
          lastreDa: selectedRow.lastreDa ?? 0,
          lastreA: selectedRow.lastreA ?? 0,
        });
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({ message: "Dettaglio diviso", kind: "success" })
        );
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
  }, [dispatch, id, data]);
};

export const useDettaglioUpdateStorage = (
  lottoId: number,
  dettaglioIds: number[],
  data: DettaglioUpdateStorageFormData
) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const effect = async () => {
      if (!dettaglioIds.length || !data) return;
      const actionToDo = "update-storage-dettaglio";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        for (let dettaglioId of dettaglioIds) {
          await updateStorageDettaglio(dettaglioId, data);
        }
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({
            message: "Informazioni di magazzino aggiornate",
            kind: "success",
          })
        );
        dispatch(updateStorageAction({ lottoId, dettaglioIds, ...data }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
  }, [dispatch, lottoId, dettaglioIds, data]);
};

export const useDettaglioUpdateCommercials = (
  lottoId: number,
  dettaglioIds: number[],
  data: DettaglioUpdateCommercialsFormData
) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const effect = async () => {
      if (!dettaglioIds.length || !data) return;
      const actionToDo = "update-commercials-dettaglio";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        for (let dettaglioId of dettaglioIds) {
          await updateCommercialsDettaglio(dettaglioId, data);
        }
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({
            message: "Informazioni commerciali aggiornate",
            kind: "success",
          })
        );
        dispatch(updateCommercialsAction({ lottoId, dettaglioIds, ...data }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
  }, [dispatch, lottoId, dettaglioIds, data]);
};

export const useDettaglioUpdateStatoLavorazione = (
  lottoId: number,
  dettaglioIds: number[],
  data: DettaglioUpdateStatoLavorazioneFormData
) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const effect = async () => {
      if (!dettaglioIds.length || !data) return;
      const actionToDo = "update-stato-lavorazione-dettaglio";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        for (let dettaglioId of dettaglioIds) {
          await updateStatoLavorazioneDettaglio(dettaglioId, data);
        }
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({
            message: "Stato lavorazione aggiornato",
            kind: "success",
          })
        );
        dispatch(
          updateStatoLavorazioneAction({ lottoId, dettaglioIds, ...data })
        );
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
  }, [dispatch, lottoId, dettaglioIds, data]);
};

export const useDettaglioUpdateCommercialOptions = (
  lottoId: number,
  dettaglioIds: number[],
  data: DettaglioUpdateCommercialOptionsFormData
) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const effect = async () => {
      if (!dettaglioIds.length || !data) return;
      const actionToDo = "update-commercial-options-dettaglio";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        for (let dettaglioId of dettaglioIds) {
          await updateCommercialOptionsDettaglio(dettaglioId, data);
        }
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({
            message: "Informazioni commerciali aggiornate",
            kind: "success",
          })
        );
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
  }, [dispatch, lottoId, dettaglioIds, data]);
};

export const useDettaglioUpdateNotes = (
  lottoId: number,
  dettaglioIds: number[],
  data: DettaglioUpdateNotesFormData
) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const effect = async () => {
      if (!dettaglioIds.length || !data) return;
      const actionToDo = "update-notes-dettaglio";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        for (let dettaglioId of dettaglioIds) {
          await updateNotesDettaglio(dettaglioId, data);
        }
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({ message: "Note aggiornate", kind: "success" })
        );
        dispatch(updateNotesAction({ lottoId, dettaglioIds, ...data }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
  }, [dispatch, lottoId, dettaglioIds, data]);
};

export const useDettaglioUpdateMeasures = (
  lottoId: number,
  dettaglioIds: number[],
  data: DettaglioUpdateMeasuresFormData
) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const effect = async () => {
      if (!dettaglioIds.length || !data) return;
      const actionToDo = "update-measures-dettaglio";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        for (let dettaglioId of dettaglioIds) {
          await updateMeasuresDettaglio(dettaglioId, data);
        }
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({ message: "Misure aggiornate", kind: "success" })
        );
        dispatch(updateMeasuresAction({ lottoId, dettaglioIds, ...data }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
  }, [dispatch, lottoId, dettaglioIds, data]);
};

export const useDettaglioSetPublish = (
  lottoId: number,
  dettaglioIds: number[],
  data: DettaglioPublishFormData
) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const effect = async () => {
      if (!dettaglioIds.length || !data) return;
      const actionToDo = "set-published-dettaglio";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        for (let dettaglioId of dettaglioIds) {
          await setWebPublishedDettaglio(dettaglioId, data);
        }
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({
            message: "Pubblicazione aggiornata",
            kind: "success",
          })
        );
        dispatch(
          updatePubishedDetailsAction({ lottoId, dettaglioIds, ...data })
        );
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
  }, [dispatch, lottoId, dettaglioIds, data]);
};
