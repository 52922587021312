import { Icon, Tooltip } from "@mui/material";
import {
  GridActionsCellItem,
  GridAlignment,
  GridColumnHeaderParams,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { settings } from "../../../../../customize/settings";
import { IDettaglio } from "../../../../../interfaces/dettaglio/models";
import { CellTipoLotto } from "../../../shared/tableCells";
import { ICreateColumnsPros, ICreateGridColumnsPros } from "./interfaces";

const i18_PATH = "prodotto.list.grid.columns.";

const createAllColumns = ({
  t,
  onView,
  rowsSelected,
  setRowsSelected,
}: ICreateColumnsPros): GridColumns<IDettaglio> => [
  {
    field: "actions",
    type: "actions",
    disableExport: true,
    align: "left" as GridAlignment,
    hideable: false,
    maxWidth: 50,
    getActions: (params: GridRowParams<IDettaglio>) => [
      <GridActionsCellItem
        icon={
          <Tooltip title={t(`${i18_PATH}Open`)}>
            <Icon
              className="fa-light fa-arrow-up-right-from-square"
              sx={{ overflow: "visible" }}
            />
          </Tooltip>
        }
        label="Open"
        onClick={() => onView(params.row.idLotto as number)}
      />,
    ],
    renderHeader: (_params: GridColumnHeaderParams<any, IDettaglio>) => (
      <>
        {!!rowsSelected.length && (
          <GridActionsCellItem
            icon={
              <Tooltip title={t(`${i18_PATH}DeselectAll`)}>
                <Icon
                  className="fa-light fa-square-check"
                  sx={{ overflow: "visible" }}
                />
              </Tooltip>
            }
            label="Open"
            onClick={() => setRowsSelected([])}
          />
        )}
        {!rowsSelected.length && <></>}
      </>
    ),
  },
  {
    field: "tipoLotto",
    headerName: t(`${i18_PATH}Tipo`) ?? "",
    type: "actions",
    renderCell: (params: GridRenderCellParams<string>) => (
      <CellTipoLotto dettaglio={params.row} />
    ),
  },
  {
    field: "codice",
    headerName: t(`${i18_PATH}Codice`) ?? "",
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.lotto?.codice,
    width: 120,
  },
  {
    field: "suffissoCodice",
    headerName: t(`${i18_PATH}SuffissoCodice`) ?? "",
    width: 40,
  },
  {
    field: "cava",
    headerName: t(`${i18_PATH}Cava`) ?? "",
    minWidth: 120,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.lotto?.cava?.nome,
  },
  {
    field: "codiceCava",
    headerName: t(`${i18_PATH}CodiceCava`) ?? "",
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.lotto?.codiceCava,
  },
  {
    field: "dataCarico",
    headerName: t(`${i18_PATH}DataCarico`) ?? "",
    type: "date",
    minWidth: 120,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.lotto?.dataCarico,
  },
  {
    field: "materiale",
    headerName: t(`${i18_PATH}Materiale`) ?? "",
    minWidth: 120,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.lotto?.materiale?.nome,
  },
  {
    field: "qualita",
    headerName: t(`${i18_PATH}Qualita`) ?? "",
    minWidth: 120,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.lotto?.qualita?.nome,
  },
  {
    field: "categoria",
    headerName: t(`${i18_PATH}Categoria`) ?? "",
    minWidth: 120,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.lotto?.categoria?.nome,
  },
  {
    field: "fondo",
    headerName: t(`${i18_PATH}Fondo`) ?? "",
    minWidth: 120,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.lotto?.fondo?.nome,
  },
  {
    field: "venatura",
    headerName: t(`${i18_PATH}Venatura`) ?? "",
    minWidth: 120,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.lotto?.venatura?.nome,
  },
  {
    field: "uso",
    headerName: t(`${i18_PATH}Uso`) ?? "",
    minWidth: 60,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.lotto?.uso?.nome,
  },
  {
    field: "disponibilita",
    headerName: t(`${i18_PATH}Disponibilita`) ?? "",
    minWidth: 120,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.disponibilita?.nome,
  },
  {
    field: "stato_lavorazione",
    headerName: t(`${i18_PATH}Lavorazione`) ?? "",
    minWidth: 120,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.stato_lavorazione?.nome,
  },
  {
    field: "pezzi",
    headerName: t(`${i18_PATH}Pezzi`) ?? "",
    type: "number",
    renderCell: (params: GridRenderCellParams<string>) => (
      <b>{params.formattedValue}</b>
    ),
    width: 60,
  },
  {
    field: "misX",
    headerName: t(`${i18_PATH}MisX`) ?? "",
    type: "number",
    width: 60,
  },
  {
    field: "misY",
    headerName: t(`${i18_PATH}MisY`) ?? "",
    type: "number",
    renderCell: (params: GridRenderCellParams<string>) => (
      <b>{params.formattedValue}</b>
    ),
    width: 60,
  },
  {
    field: "misZ",
    headerName: t(`${i18_PATH}MisZ`) ?? "",
    type: "number",
    renderCell: (params: GridRenderCellParams<string>) => (
      <b>{params.formattedValue}</b>
    ),
    width: 60,
  },
  {
    field: "quantitaUM",
    headerName: t(`${i18_PATH}Quantita`) ?? "",
    type: "number",
    renderCell: (params: GridRenderCellParams<number>) => {
      const value = parseFloat(String(params.value).replaceAll(",", "."));
      return Number(value.toFixed(2)).toLocaleString("it-IT");
    },
    width: 80,
  },
  {
    field: "um",
    headerName: t(`${i18_PATH}Um`) ?? "",
    sortable: false,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.value ? String(params.value) : "",
    width: 20,
  },
  {
    field: "prezzo",
    headerName: t(`${i18_PATH}Prezzo`) ?? "",
    type: "number",
    renderCell: (params: GridRenderCellParams<number>) => {
      const value = parseFloat(String(params.value).replaceAll(",", "."));
      return Number(value)
        ? Number(value.toFixed(2)).toLocaleString("it-IT")
        : "-------";
    },
    width: 120,
  },
  {
    field: "um_prezzo",
    headerName: t(`${i18_PATH}UmPrezzo`) ?? "",
    sortable: false,
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.um ? String(params?.row?.um) : "",
    width: 20,
  },
  {
    field: "magazzino",
    headerName: t(`${i18_PATH}Magazzino`) ?? "",
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.magazzino?.nome,
    minWidth: 120,
    flex: 1,
  },
  {
    field: "zona",
    headerName: t(`${i18_PATH}Zona`) ?? "",
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.zona?.nome,
    minWidth: 80,
    flex: 1,
  },
  {
    field: "fila",
    headerName: t(`${i18_PATH}Fila`) ?? "",
    valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
      params?.row?.fila?.nome,
    minWidth: 80,
    flex: 1,
  },
  {
    field: "opzCliente",
    headerName: t(`${i18_PATH}Cliente`) ?? "",
    minWidth: 220,
    flex: 1,
  },
  {
    field: "opzNote",
    headerName: t(`${i18_PATH}Note`) ?? "",
    sortable: false,
    minWidth: 380,
    flex: 1,
  },
];

export const createDefaultGridColumns = ({
  t,
  canViewQuarry,
  canViewCommercials,
  canViewStoragePlace,
  canViewCommercialOptions,
  isGrouped,
  rowsSelected,
  setRowsSelected,
  onView,
}: ICreateGridColumnsPros): GridColumns<IDettaglio> => {
  let columns = createAllColumns({ t, onView, rowsSelected, setRowsSelected });
  const columnsToHide = settings.list.grid?.columnsToHide ?? [];
  columns = columns.filter((col) => !columnsToHide.includes(col.field));
  if (isGrouped)
    columns = columns.filter(
      (col) => !["misX", "misY", "misZ"].includes(col.field)
    );
  if (!canViewQuarry)
    columns = columns.filter(
      (col) => col.field !== "cava" && col.field !== "codiceCava"
    );
  if (!canViewCommercials)
    columns = columns.filter(
      (col) => col.field !== "prezzo" && col.field !== "um_prezzo"
    );
  if (!canViewStoragePlace)
    columns = columns.filter(
      (col) =>
        col.field !== "magazzino" &&
        col.field !== "zona" &&
        col.field !== "fila"
    );
  if (!canViewCommercialOptions)
    columns = columns.filter(
      (col) =>
        col.field !== "opzStato" &&
        col.field !== "opzCliente" &&
        col.field !== "agente" &&
        col.field !== "opzNote"
    );
  return columns;
};
