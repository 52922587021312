import {
  Collapse,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ITipoLotto } from "../../../../../../../interfaces/dettaglio/attributes/models";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IFilterProps } from "../../interfaces";

const i18_PATH = "prodotto.list.filters.";

function TipiLottoFilter({ filters, setFilters }: IFilterProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const tipoLottoList = useAppSelector(
    (state) => state.dettaglio_tipoLotto.list
  );
  const tipoLottoItems = useAppSelector(
    (state) => state.dettaglio_tipoLotto.items
  );

  const list: ITipoLotto[] = useMemo(
    () =>
      !!tipoLottoList && !!tipoLottoItems
        ? tipoLottoList.map((id) => tipoLottoItems[id])
        : [],
    [tipoLottoList, tipoLottoItems]
  );
  const toggle = () => {
    setOpen(!open);
  };

  const toggleSelected = (item: ITipoLotto) => {
    if (isFilterAndValueSelected(filters, EOptionType.tipoLotto, item.id)) {
      setFilters(
        filters.filter(
          (opt) =>
            !(
              opt.type === EOptionType.tipoLotto && opt.numericValue === item.id
            )
        )
      );
    } else {
      setFilters([
        ...filters,
        {
          type: EOptionType.tipoLotto,
          numericValue: item.id,
          stringValue: item.nome,
        },
      ]);
    }
  };

  return (
    <>
      <ListItemButton onClick={toggle}>
        <ListItemIcon>
          <Icon className="fa-light fa-shapes" sx={{ overflow: "visible" }} />
        </ListItemIcon>
        <ListItemText primary={t(`${i18_PATH}Prodotto`)} />
        {open ? (
          <Icon
            className="fa-light fa-angle-up"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        ) : (
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true}>
          {list.map((item) => (
            <ListItemButton
              key={item.id}
              sx={{ display: "flex", pl: 5, pr: 1 }}
              onClick={() => toggleSelected(item)}
              selected={isFilterAndValueSelected(
                filters,
                EOptionType.tipoLotto,
                item.id
              )}
            >
              <ListItemText sx={{ flex: 1 }} primary={item.nome} />
              <ListItemIcon sx={{ minWidth: 0 }}>
                {isFilterAndValueSelected(
                  filters,
                  EOptionType.tipoLotto,
                  item.id
                ) && (
                  <Icon
                    className="fa-light fa-check"
                    sx={{ overflow: "visible" }}
                  />
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default TipiLottoFilter;
