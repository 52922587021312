import { Box } from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useQualitaExtList } from "../../../effects/lotto/attributes/qualita";
import { useAppSelector } from "../../../store/hooks";
import Table from "./components/table/table";

function List() {
  const navigate = useNavigate();
  useQualitaExtList(1);
  const { listExt: list, itemsExt: items } = useAppSelector(
    (state) => state.lotto_qualita
  );

  const rows = useMemo(
    () =>
      !!list && !!items
        ? list
            .map((item) => items[item])
            .sort((a, b) => (a.nome > b.nome ? 1 : -1))
        : [],
    [list, items]
  );
  const edit = (id: number) => navigate(`${id}`);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: "#fff",
      }}
    >
      <Table rows={rows} onEdit={edit}></Table>
    </Box>
  );
}

export default List;
