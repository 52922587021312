import {
  Collapse,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IFila } from "../../../../../../../interfaces/place/models";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IFilterProps } from "../../interfaces";

const i18_PATH = "prodotto.list.filters.";

function FileFilter({ filters, setFilters }: IFilterProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const filaList = useAppSelector((state) => state.place_fila.list);
  const filaItems = useAppSelector((state) => state.place_fila.items);
  const dettaglioItems = useAppSelector((state) => state.dettaglio.items || {});

  const list: IFila[] = useMemo(() => {
    const idMagazzino = filters.find(
      (f) => f.type === EOptionType.magazzino && !!f.numericValue
    )?.numericValue as number;
    const idZona = filters.find(
      (f) => f.type === EOptionType.zona && !!f.numericValue
    )?.numericValue as number;
    const _file: IFila[] =
      !!filaList && !!filaItems ? filaList.map((id) => filaItems[id]) : [];
    const selectableFileIds: number[] = Object.values(dettaglioItems)
      .filter(
        (item) => item.idMagazzino === idMagazzino && item.idZona === idZona
      )
      .map((item) => item.idFila || 0)
      .filter(Boolean);
    return _file
      .filter((fila) => selectableFileIds.includes(fila.id))
      .sort((a, b) => (a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1));
  }, [filaList, filaItems, dettaglioItems, filters]);

  const toggle = () => {
    setOpen(!open);
  };

  const toggleSelected = (item: IFila) => {
    if (isFilterAndValueSelected(filters, EOptionType.fila, item.id)) {
      setFilters(filters.filter((opt) => !(opt.type === EOptionType.fila)));
    } else {
      setFilters([
        ...filters.filter((opt) => !(opt.type === EOptionType.fila)),
        {
          type: EOptionType.fila,
          numericValue: item.id,
          stringValue: item.nome,
        },
      ]);
    }
  };

  return (
    <>
      <ListItemButton onClick={toggle}>
        <ListItemIcon>
          <Icon
            className="fa-light fa-lines-leaning"
            sx={{ overflow: "visible" }}
          />
        </ListItemIcon>
        <ListItemText primary={t(`${i18_PATH}Fila`)} />
        {open ? (
          <Icon
            className="fa-light fa-angle-up"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        ) : (
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true}>
          {list.map((item) => (
            <ListItemButton
              key={item.id}
              sx={{ display: "flex", pl: 5, pr: 1 }}
              onClick={() => toggleSelected(item)}
              selected={isFilterAndValueSelected(
                filters,
                EOptionType.fila,
                item.id
              )}
            >
              <ListItemText sx={{ flex: 1 }} primary={item.nome} />
              <ListItemIcon sx={{ minWidth: 0 }}>
                {isFilterAndValueSelected(
                  filters,
                  EOptionType.fila,
                  item.id
                ) && (
                  <Icon
                    className="fa-light fa-check"
                    sx={{ overflow: "visible" }}
                  />
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default FileFilter;
